/**
 * Action type
 */

// menu
export const FETCH_MENUS = 'FETCH_MENUS' as const;
export const FETCH_NEW_MENUS = 'FETCH_NEW_MENUS' as const;
export const FETCH_FEATURE_MENUS = 'FETCH_FEATURE_MENUS' as const;
export const FETCH_TAXONOMY_MENUS = 'FETCH_TAXONOMY_MENUS' as const;
export const FETCH_SAME_CATEGORY_MENUS = 'FETCH_SAME_CATEGORY_MENUS' as const;
export const FETCH_FAVORITED_MENUS = 'FETCH_FAVORITED_MENUS' as const;
export const FETCH_MENU = 'FETCH_MENU' as const;
export const ERROR_MENU = 'ERROR_MENU' as const;
export const RESET_MENUS = 'RESET_MENUS' as const;
// idea
export const FETCH_IDEAS = 'FETCH_IDEAS' as const;
export const FETCH_NEW_IDEAS = 'FETCH_NEW_IDEAS' as const;
export const FETCH_FAVORITED_IDEAS = 'FETCH_FAVORITED_IDEAS' as const;
export const FETCH_IDEA = 'FETCH_IDEA' as const;
export const ERROR_IDEA = 'ERROR_IDEA' as const;
export const RESET_IDEAS = 'RESET_IDEAS' as const;
// shopping
export const FETCH_SHOPPINGS = 'FETCH_SHOPPINGS' as const;
export const FETCH_FEATURE_SHOPPINGS = 'FETCH_FEATURE_SHOPPINGS' as const;
export const FETCH_RELATION_SHOPPINGS = 'FETCH_RELATION_SHOPPINGS' as const;
export const FETCH_SAME_CATEGORY_SHOPPINGS = 'FETCH_SAME_CATEGORY_SHOPPINGS' as const;
export const FETCH_FAVORITED_SHOPPINGS = 'FETCH_FAVORITED_SHOPPINGS' as const;
export const FETCH_SHOPPING = 'FETCH_SHOPPING' as const;
export const ERROR_SHOPPING = 'ERROR_SHOPPING' as const;
export const RESET_SHOPPINGS = 'RESET_SHOPPINGS' as const;
// menuTaxonomy
export const FETCH_MENUS_TAXONOMY = ' FETCH_MENUS_TAXONOMY' as const;
export const GET_MENUS_TAXONOMY_TITLES = 'GET_MENUS_TAXONOMY_TITLES' as const;
// user
export const SET_CREDENTIAL_USER = 'SET_CREDENTIAL_USER' as const;
export const RESET_CREDENTIAL_USER = 'RESET_CREDENTIAL_USER' as const;
export const UPDATE_USER = 'UPDATE_USER' as const;
export const SET_CURRENT_USER = 'SET_CURRENT_USER' as const;
// comments
export const FETCH_COMMENTS = 'FETCH_COMMENTS' as const;
