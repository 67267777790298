import React from 'react';

const PageChange: React.FC = () => {
  return (
    <div>
      <div className='loading-wrapper'>
        <div className='loading' />
      </div>
    </div>
  );
};

export default PageChange;
